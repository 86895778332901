import http from '@/http-client'
import { AbsenceModel } from '@/models/app-user-dto'
import { BehaviorSubject } from 'rxjs'

export class UserprofileService {
  private static instance: UserprofileService
  private _absenceSelectedSource = new BehaviorSubject<AbsenceModel>({ isAllDay: true, period: 1 })
  public absenceObservationSelected$ = this._absenceSelectedSource.asObservable()

  public static getInstance (): UserprofileService {
    if (!UserprofileService.instance) {
      UserprofileService.instance = new UserprofileService()
    }
    return UserprofileService.instance
  }

  // manage user absence
  public pushAbsence (absence: AbsenceModel) {
    this._absenceSelectedSource.next(absence)
  }

  public async getAllAbsences () {
    const response = await http.get('/account/absences')
    return (response.data) as AbsenceModel[]
  }

  public async GetUserAbsences (userId: string) {
    const response = await http.get(`/account/absence/${userId}`)
    return (response.data) as AbsenceModel[]
  }

  public async AddAbsence (userId: string, absence: AbsenceModel) {
    const response = await http.post(`/account/absence/${userId}`, absence)
    return (response.data)
  }

  public async DeleteAbsence (absenceId: string) {
    const response = await http.delete(`/account/absence/${absenceId}`)
    return (response.data)
  }
}
