import { Component, Vue, Watch } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { LayoutService } from '@/services/layout-service'
import { AuthService } from '@/services/auth-service'
import { RoomService } from '@/services/room-service'
import { ErrorService } from '@/services/error.service'
import { getSoinName, getSoinNameShort } from '../../Constants'
import { AppUser, AbsenceModel } from '@/models/app-user-dto'
import { RoomModel, RoomPlanningPeriodModel, RoomPlanningModel } from '@/models/rooms-model'
import Commons from '@/components/shared/Helpers/commons'
import RoomHelpers from '@/components/shared/Helpers/RoomHelpers'
import { UserprofileService } from '@/services/user-profile-service'
import AgendaHelpers from '@/components/shared/Helpers/agenda-helpers'
import { AdministrationHelper } from '../../AdministrationHelper'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver,
    ValidationProvider
  }
})
export default class Planning extends Vue {
  private layoutService = LayoutService.getInstance()
  private authService = AuthService.getInstance()
  private roomService = RoomService.getInstance()
  private absenceService = UserprofileService.getInstance()

  public roomPlanningPeriod: RoomPlanningPeriodModel = {}
  public allInfirmieres: AppUser[] = []
  public rooms: RoomModel[] = []
  public allAbsences: AbsenceModel[] = []
  public errorMessages: string[] = []

  private ready = false
  private readyRooms = false
  private readyUsers = false
  private readyPlanning = false
  private readyAbsences = false

  public isSaving = false
  public isEdit = false

  public weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi']
  public weekDaysNumber = [1, 2, 3, 4, 5]
  public days: number[] = []
  public oneWeekPeriod = false
  public showConfirm = false

  public mounted () {
    this.ready = false
    this.layoutService.updateDrawerList(AdministrationHelper.GetAdminNavItems())
    this.days = this.weekDaysNumber

    this.isEdit = false
    const planningPeriodId = Number.parseInt(this.$route.params.planningPeriodId, 10)
    if (!isNaN(planningPeriodId)) {
      this.roomService.getPlanningPeriod(planningPeriodId)
        .then(p => {
          this.roomPlanningPeriod = p
          if (this.$route.params.duplicate === 'true') {
            this.roomPlanningPeriod.description = undefined
            this.roomPlanningPeriod.dateRange = undefined
            this.roomPlanningPeriod.id = undefined
          }
        }).catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.readyPlanning = true
        })
      if (this.$route.params.duplicate !== 'true') {
        this.isEdit = true
      }
    } else {
      this.readyPlanning = true
    }

    this.getAllRooms()
    this.getAllInfirmieres()
    this.getAllAbsences()
  }

  private updateReadyState () {
    this.ready = this.readyRooms && this.readyUsers && this.readyPlanning && this.readyAbsences
    if (this.ready) {
      RoomHelpers.MapRoomPlanningPeriod(this.allInfirmieres, this.rooms, this.roomPlanningPeriod)

      if (this.isEdit) {
        this.$nextTick(async () => {
          const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
          await observer.validate()
        })
      }
    }
  }

  @Watch('readyRooms')
  public readyRoomsChanged () {
    this.updateReadyState()
  }

  @Watch('readyUsers')
  public readyUsersChanged () {
    this.updateReadyState()
  }

  @Watch('readyPlanning')
  public readyPlanningChanged () {
    this.updateReadyState()
  }

  @Watch('readyAbsences')
  public readyAbsencesChanged () {
    this.updateReadyState()
  }

  public async getAllInfirmieres () {
    this.readyUsers = false
    await this.authService.getAllInfirmiereGroupUsers(true).then((users) => {
      this.allInfirmieres = users
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyUsers = true
    })
  }

  public getAllRooms () {
    this.readyRooms = false
    this.roomService.getAllActiveRooms().then((rooms) => {
      rooms.sort(function (a, b) {
        return (a.site ?? '').localeCompare(b.site ?? '') || (a.name ?? '').localeCompare(b.name ?? '')
      })
      this.rooms = rooms
      this.addAllActiveRoomsToPlanning()
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyRooms = true
    })
  }

  public getAllAbsences () {
    this.readyAbsences = false
    this.absenceService.getAllAbsences().then((absences) => {
      this.allAbsences = absences
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyAbsences = true
    })
  }

  public addAllActiveRoomsToPlanning () {
    const roomPlannings: RoomPlanningModel[] = []
    this.rooms.forEach((room: RoomModel) => {
      if (this.roomPlanningPeriod.roomPlannings) {
        const rp = this.roomPlanningPeriod.roomPlannings.find(p => p.roomId === room.id)
        if (rp) {
          roomPlannings.push(rp)
          return
        }
      }
      const newRoomPlanning: RoomPlanningModel =
      {
        roomId: room.id,
        roomPlanningPeriodId: this.roomPlanningPeriod.id,
        siteId: room.siteId,
        roomSoinsTypeIds: room.soinsTypeIds,
        room: room.name,
        site: room.site
      }
      roomPlannings.push(newRoomPlanning)
    })
    this.roomPlanningPeriod.roomPlannings = roomPlannings
  }

  public getTypeName (typeId: number) {
    return getSoinName(typeId)
  }

  public getTypeNameShort (typeId: number) {
    return getSoinNameShort(typeId)
  }

  public getDayNameByDayNr (day: number) {
    return this.weekDays[day - 1]
  }

  public getCommonSoinsTypes (roomSoinsTypeIds: number[], nurseId?: string) {
    return RoomHelpers.GetCommonSoinsTypesWithNurse(this.allInfirmieres, roomSoinsTypeIds, nurseId)
  }

  public dateUpdated () {
    if (this.roomPlanningPeriod?.dateRange?.from && this.roomPlanningPeriod?.dateRange?.to) {
      const from = new Date(this.roomPlanningPeriod.dateRange.from)
      const to = new Date(this.roomPlanningPeriod.dateRange.to)
      const diffTime = Math.abs(to.getTime() - from.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays > 6) {
        this.days = this.weekDaysNumber
        this.oneWeekPeriod = false
      } else {
        this.oneWeekPeriod = true
        let newDays: number[] = []
        let d = from.getDay()
        for (let i = 0; i <= diffDays; ++i) {
          if (d > 6) {
            d = 0
          }
          newDays.push(d++)
        }
        newDays = newDays.filter(d => d > 0 && d < 6)
        this.days = []
        newDays.forEach((newDay: number) => {
          this.days.push(newDay)
        })
      }
    }
  }

  public filterInfirmieres (soinsTypeIds, day) {
    const infirmieres = this.allInfirmieres.filter(u => u.soinsTypeIds.some(i => soinsTypeIds?.includes(i)))
    return this.filterInfirmieresWithAbsence(infirmieres, day, '')
  }

  public filterInfirmieresWithCurrent (soinsTypeIds, day, currentNurseId) {
    const infirmieres = this.allInfirmieres.filter(u => u.soinsTypeIds.some(i => soinsTypeIds?.includes(i)) || u.id.toLowerCase() === currentNurseId)
    return this.filterInfirmieresWithAbsence(infirmieres, day, currentNurseId)
  }

  private filterInfirmieresWithAbsence (infirmieres: AppUser[], day, currentNurseId) {
    if (this.oneWeekPeriod && this.roomPlanningPeriod?.dateRange?.from && this.roomPlanningPeriod?.dateRange?.to) {
      const dayIndex = this.days.indexOf(day)
      let dayDiff = dayIndex
      if (dayIndex > 0) {
        for (let i = 0; i < dayIndex; i++) {
          if (this.days[i] > this.days[dayIndex]) {
            dayDiff += 2
            break
          }
        }
      }
      const from = new Date(this.roomPlanningPeriod.dateRange.from)
      const dayDate = new Date(from.getFullYear(), from.getMonth(), from.getDate() + dayDiff)
      return AgendaHelpers.FilterNursesByAbsence(infirmieres, this.allAbsences, dayDate, dayDate, currentNurseId)
    }
    return infirmieres
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      if (this.isEdit) {
        this.showConfirm = true
      } else {
        await this.doSave()
      }
    } else {
      let merged = this.$refs
      if (this.$refs.DateRef) {
        const dateRefs = (this.$refs.DateRef as Vue).$refs
        merged = Object.assign(merged, dateRefs)
        for (const prop in dateRefs) {
          if (Object.prototype.hasOwnProperty.call(dateRefs, prop)) {
            if (prop.startsWith('DateField')) {
              merged = Object.assign(merged, (dateRefs[prop] as Vue).$refs)
            }
          }
        }
      }
      merged.DateEndRef = merged.DateStartRef = merged['DateField-DateRef']
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  private async doSave () {
    this.isSaving = true
    const res = await this.roomService.addUpdateRoomPlanningPeriod(this.roomPlanningPeriod)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isSaving = false
      })
    if ((res as AxiosResponse<any>)?.status === 200) {
      this.returnToList()
    }
  }

  public async confirmSaveCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      await this.doSave()
    }
  }

  public canEditPlanning () {
    return this.hasWriteAccess() && (!this.isEdit || RoomHelpers.CanEditPlanning(this.roomPlanningPeriod))
  }

  public returnToList () {
    sessionStorage.setItem('goToPlanningDesSalles', JSON.stringify(true))
    this.$router.go(-1)
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public hasWriteAccess () {
    return ModuleAuthorisationManager.HasAccess('admin.roomManage.writeOperations')
  }
}
